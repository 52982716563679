@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;700&display=swap');

html {
    /* Hide scrollbar for webkit browsers */
    ::-webkit-scrollbar {
      display: none;
    }
  
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  box-sizing: border-box;
  background: #f5f4f0;


  /* Hide scrollbar for webkit browsers */
  ::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.rs-input-group{
  box-sizing: border-box !important;
  border: none !important;
  outline-style: none !important;
  background-color: transparent !important;
}

.rs-input{
  box-sizing: border-box !important;
  background-color: transparent !important;
}